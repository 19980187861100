import React from "react";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { graphql } from "gatsby";
import { Blocks } from "../components/Blocks";

export default ({
  data: {
    pageAssembly: { blocks },
    localizedArticles: { nodes: localizedArticles },
    articles: { nodes: articles },
  },
  pageContext: { pageWrapper, locale },
}) => {
  const articlesOnMain = [...localizedArticles, ...articles].slice(0, 3);

  if (!blocks) return <PageWrapper variant={pageWrapper}></PageWrapper>;

  const hasPageHero = blocks[0].__typename == "ContentfulBlockHero";

  if (hasPageHero) {
    return (
      <Blocks
        blocks={blocks}
        data={{ articles: articlesOnMain }}
        locale={locale}
      />
    );
  }

  return (
    <PageWrapper variant={pageWrapper}>
      <Blocks
        blocks={blocks}
        data={{ articles: articlesOnMain }}
        locale={locale}
      />
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query LandingPageQuery(
    $locale: String!
    $contentful_id: String!
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    localizedArticles: allContentfulKnowledgeHubArticle(
      limit: 3
      filter: {
        category: { featureInNews: { ne: true } }
        node_locale: { eq: $locale }
        localContent: { slug: { ne: null }, language: { eq: $locale } }
      }
      sort: { fields: [publishedDate, createdAt], order: DESC }
    ) {
      totalCount
      nodes {
        ...KnowledgeHubArticleCard
      }
    }
    articles: allContentfulKnowledgeHubArticle(
      limit: 3
      filter: {
        category: { featureInNews: { ne: true } }
        node_locale: { eq: $locale }
        localContent: { slug: { ne: null }, language: { ne: $locale } }
      }
      sort: { fields: [publishedDate, createdAt], order: DESC }
    ) {
      totalCount
      nodes {
        ...KnowledgeHubArticleCard
      }
    }
    pageAssembly: contentfulLandingPageAssembly(
      node_locale: { eq: $locale }
      contentful_id: { eq: $contentful_id }
    ) {
      contentful_id
      headerMode
      headerVariant
      pageWrapper
      title
      node_locale
      page {
        contentful_id
        footerTeaser {
          contentful_id
        }
        seoMeta {
          slug
          title
          description
        }
      }
      blocks {
        __typename
        ...HeroBlockFields
        ...CarouselBlockFields
        ...ServicesBlockFields
        ...ImageGridBlockFields
        ...TextImageBlockFields
        ...FeaturesBlockFields
        ...RichTextBlockFields
        ...FeatureDisplayBlockFields
        ...CounterFields
        ...KnowledgeHubBlockFields
        ...SectionBlockFields
        ...CustomerStoryCarouselFields
        ...KnowledgeHubTagFields
        ...PageTypeFields
      }
    }
  }
`;
